.card.custom .card-header {
    border-radius: 10px 10px 0px 0px;
}
.card.custom .text-muted {
    padding-left: 5px;
}

/* animation card shadow */
.card.custom {
    /*border: none;*/
    border-width: 0.5px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px;
}
.card.custom:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 15px;
}

.card.custom .card-body {
    box-shadow: black 2px 0px 5px;
    z-index: 10;
    background-color: var(--card-background);
}

.card.custom img.rounded {
    width: 100%;
}

/* .card.custom .button-area .text {

} */
.card.custom .img-container {
    position: relative;
    overflow: hidden;
}
.card.custom .button-area {
    z-index: 2;
    text-align: right;
}
.card.custom .text.active {
    color: var(--white);
    z-index: 5;
    position: absolute;
    right: -5px;
    left: -5px;
    bottom: 0px;
    text-align: right;
    border-radius: 0px;
    padding: 8px 8px 8px 0px;
    overflow: hidden;

    box-shadow: black 0px 0px 5px;
}
.card.custom .buttons {
    z-index: 20;
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-radius: 0px 15px 0px 0px;
    padding: 2px 2px 0px 0px;
    overflow: hidden;
    box-shadow: black 0px 0px 5px;
    
    color: var(--dark);
    background-color: var(--card-background);
}

/* animation view buton */
.card.custom .buttons .button:hover svg {
    fill: var(--pu-pink);
    /*-webkit-filter: drop-shadow( 0px 0px 8px var(--dark) );
    filter: drop-shadow( 0px 0px 8px var(--dark) );*/
}
.card.custom .buttons .button.view-button svg {
    margin: 11px 11px 11px 14px;
}
/* animation action button */
.card.custom .buttons .button:hover .action {
    color: var(--pu-pink);
    -webkit-filter: drop-shadow( 0px 0px 8px var(--dark) );
    filter: drop-shadow( 0px 0px 8px var(--dark) );
}

/* button alignment */
.card.custom .buttons .button {
    cursor: pointer;
    display: inline-block;
}
.card.custom .buttons .separator {
    display: inline;
    background-color: var(--dark);
    padding: 0px 0px 5px 1px;
}
.card.custom .buttons .action {
    display: inline;
    padding: 0.375rem 0.375rem;
}

.card.custom svg.icon {
    margin-top: -3px;
    margin-left: 3px;
    margin-right: 3px;
}
.card.custom .card-body svg.icon {
    margin-top: -6px;
}

/* image effects based on icon */
.card.custom img.locked {
    opacity: 0.6;
    filter: blur(50px) grayscale(1);
}
/* .card.custom img.active {
    border: 2px solid var(--success);
    border-radius: 10px;
    filter: blur(1px);
} */
.card.custom img.finished {
    filter: grayscale(0.8);
}
/* animation remove image filter */
.card.custom:hover img.finished, .card.custom:hover img.active {
    filter: grayscale(0);
}
.card.custom:hover img {
    filter: blur(0px) grayscale(0);
}