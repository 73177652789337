.tier-navigation-container.container-fluid {
    padding: 15px;
}
.tier-navigation-container.container-fluid .row .tier-navigation {
    margin-left: 15px;
    display: block;
}
.tier-navigation-container .tier-navigation .nav-item svg.icon {
    margin-top: -6px;
}
.tier-navigation-container .tier-navigation-mobile {
    position: fixed;
    z-index: 25;
    left: -15px;
    text-decoration: none;
    font-size: 20px;
}

.tier-navigation-container .tier-navigation-mobile button {
    transform: rotate( 90deg );
}