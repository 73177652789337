.image-holder img {
    width: 100%;
    border-radius: 10px;
}

.image-holder .artistInfo {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 2px 23px;
}

.image-holder .artistInfo svg {
    fill: var(--light);
    -webkit-filter: drop-shadow( 0px 0px 1px var(--pu-pink) ) drop-shadow( 0px 0px 12px black );
    -moz-filter: drop-shadow( 0px 0px 1px var(--pu-pink) ) drop-shadow( 0px 0px 12px black );
    -o-filter: drop-shadow( 0px 0px 1px var(--pu-pink) ) drop-shadow( 0px 0px 12px black );
    filter: drop-shadow( 0px 0px 1px var(--pu-pink) ) drop-shadow( 0px 0px 12px black );
}

.image-holder .artistInfo:hover svg {
    fill: var(--white);
    -webkit-filter: drop-shadow( 0px 0px 1px var(--pu-pink) ) drop-shadow( 0px 0px 8px var(--pu-pink) );
    -moz-filter: drop-shadow( 0px 0px 1px var(--pu-pink) ) drop-shadow( 0px 0px 8px var(--pu-pink) );
    -o-filter: drop-shadow( 0px 0px 1px var(--pu-pink) ) drop-shadow( 0px 0px 8px var(--pu-pink) );
    filter: drop-shadow( 0px 0px 1px var(--pu-pink) ) drop-shadow( 0px 0px 8px var(--pu-pink) );
}

/* .modal-dialog.modal-xl {
    max-width: initial;
    margin: 8px;
}

.modal-dialog.modal-xl .modal-body .container {
    max-width: inherit;
} */

.image-holder .text.active {
    color: var(--white);
    z-index: 5;
    position: relative;
    text-align: center;
    border-radius: 0px 0px 9px 9px;
    padding: 8px 15px 8px 15px;
    margin-top: -36px;
}

.image-holder svg.icon, .modal-dialog.modal-xl .modal-header svg.icon {
    margin-top: -3px;
    margin-left: 3px;
    margin-right: 3px;
}

.modal-dialog.modal-xl .modal-footer .action-button svg {
    margin: 1px 0px 5px 0px;
}
.modal-dialog.modal-xl .modal-footer .action-button:hover svg {
    fill: var(--pu-pink);
}
.modal-dialog.modal-xl .modal-footer .action-button:hover {
    color: var(--pu-pink);
    -webkit-filter: drop-shadow( 0px 0px 8px var(--dark) );
    filter: drop-shadow( 0px 0px 8px var(--dark) );
}