:root {
  --pu-pink: #ff0055;
  --pu-pink-old: #f0a8c0;
  --card-background: var(--light)
}

body.modal-open .App {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

body .App {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

div.modal-backdrop.show {
  opacity: 0.7;
}

.animation, .animation:hover {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  /*background: 
   color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--pu-pink);
  border-radius: 20px;
  border: 3px solid transparent;
  /* roundness of the scroll thumb */
  /*border: 3px solid orange;  creates padding around scroll thumb */
}