canvas {
    display: block;
}

#PixelPaint {
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    /*
    height: calc(100vh - 56px);
    width: 100%;
    position: fixed;
    */
}

#PixelGenerator img.pixel {
    image-rendering: pixelated;
}

#PixelColorSelector {
    --bottom: 22px;
    --navheight: 56px;
    --buttonSize: 43px;
    --negativeButtonSize: -43px;
    position: relative;
    padding: 10px;
    display: inline-flex;
    flex-direction: column;
}

#PixelColorSelector .Buttons {
    position: relative;
    z-index: 10;
}

#PixelColorSelector .Buttons div.btn {
    border-radius: 20px;
    width: var(--buttonSize);
    height: var(--buttonSize);
    margin-bottom: 5px;
}

#PixelColorSelectorPopover {
    max-width: initial;
}

#PixelColorSelectorInner {
    overflow-y: auto;
    position: relative;
    z-index: 9;
    margin-top: var(--negativeButtonSize);
}

#PixelColorSelectorInner div.btn, #PixelColorSelectorInner div.spacerStart, #PixelColorSelectorInner div.spacerEnd {
    width: var(--buttonSize);
}

#PixelColorSelectorInner div.btn.active {
    z-index: 11;
    border-color: white;
}

#PixelColorSelectorInner div.spacerStart {
    padding-top: var(--buttonSize);
    border-radius: 20px 20px 0px 0px;
}

#PixelColorSelectorInner div.spacerEnd {
    padding-bottom: 20px;
    border-radius: 0px 0px 20px 20px;
}