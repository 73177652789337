.is-clickable {
    cursor: pointer;
}
.no-overflow {
    overflow: hidden;
}
.card-container {
    min-width: 240px;
}
.task-list-item button svg, .task-container svg {
    margin: 1px 0px 3px 0px;
}
.task-list-item .full-container {
    display: flex;
    align-items: stretch;
}