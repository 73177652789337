#Notifications {
    z-index: 1000;
    width: 350px;
    position: absolute;
    right: 0px;
}

#Toasts div.toast {
    margin: 10px;
}

#Toasts .toast-header svg {
    margin: 0px 3px -1px 0px;
}