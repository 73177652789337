.card .card-title svg {
    margin: 0px 0px 4px 0px;
}

.taskFinished {
    text-decoration: line-through;
    color: var(--dark);
}

.subtask .description svg {
    margin: 0px 3px 3px 0px;
}